import axios from "axios";
import baseUrl from "./baseUrl";
const route: string = "auth/login";

// token goes here
// const token: string = "";

// const headers = {
//   Accept: "application/json",
// };

const post = (user: any) => {
  return axios.post(`${baseUrl}${route}`, user);
};

export default post;
