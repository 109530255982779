import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import post from "../API/SignupAPI";
import Footer from "../pages/Home/Footer";
import Nav from "../pages/Home/Nav";
import Input from './Input/index';
import "./signup.scss";

export default () => {
  const { register, handleSubmit, errors } = useForm();
  const [status, setStatus] = useState({
    error: null,
    success: null,
    loading: false,
  });
  const onSubmit = (data: any) => {
    setStatus({ ...status, loading: true });
    data = { ...data, roles: ["ROLE_USER"] };
    post(data)
      .then((res: any) => {
        setStatus({ ...status, success: res.data.messag, loading: false });
        let form: any = document.getElementById("signup");
        form.reset();
      })
      .catch((error: any) => {
        setStatus({
          ...status,
          error: error.response.data.error,
          loading: false,
        });
      });
  };

  return (
    <div className="signup">
      <Nav page="signup" />
      <div className="signup__formContainer">
        {(status.error && (
          <div
            style={{ background: `#fa3600`, color: "#fff", padding: "1rem" }}
          >
            {status.error}
          </div>
        )) ||
          (status.success && (
            <div
              style={{ background: `#c6f0ce`, color: "#fff", padding: "1rem" }}
            >
              {status.success}
            </div>
          ))}
        <form onSubmit={handleSubmit(onSubmit)} id="signup">
        
          <div className="signup__title">
            <h2 className="">Create Account</h2>
          </div>
          <div className="signup__socials">
            <div className="signup__facebook">Facebook</div>
            <div className="signup__google">Google</div>
          </div>

          <Input
            register={register}
            type="text"
            className=""
            name="full_name"
            placeholder="Full Name"
            errors={errors}
            required={true}
          />  
          <Input
            register={register}
            type="email"
            className=""
            name="email"
            placeholder="Email"
            errors={errors}
            required={true}
          />   
          <Input
            register={register}
            type="text"
            className=""
            name="username"
            placeholder="Username"
            errors={errors}
            required={true}
          />  
          <Input
            register={register}
            type="password"
            className=""
            name="password"
            placeholder="Password"
            errors={errors}
            required={true}
          /> 
          <Input
            register={register}
            type="text"
            className=""
            name="address"
            placeholder="Address"
            errors={errors}
            required={true}
          />
          
          <div className="signup__ctaBtn">
            <button className=""  type="submit" disabled={status.loading}>
              {(status.loading && "Loading, wait") || "Create Account"}
            </button>
          </div>
          <div className="signup__loginLink">
            <p>
              Have an Account already? <Link to="/login" style={{color: "#2612b2", display: "inline"}}>Login</Link>
            </p>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};
