import * as React from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import post from "../API/SigninAPI";
import Footer from "../pages/Home/Footer";
import Nav from "../pages/Home/Nav";
import Input from "./Input/index";
import "./singin.scss";

const Signin = () => {
  let history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [status, setStatus] = React.useState({
    error: null,
    success: null,
    loading: false,
  });

  const onSubmit = (data: any) => {
    setStatus({ ...status, loading: true });
    data = { ...data, roles: ["ROLE_USER"] };

    post(data)
      .then((res: any) => {
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.data));
        setStatus({ ...status, success: res.data.status, loading: false });
        history.push("/dashboard");
      })
      .catch((error: any) => {
        setStatus({
          ...status,
          error: error.response.data.error,
          loading: false,
        });
      });
  };

  return (
    <div className="signin">
      <Nav page="signin" />
      <div className="signin__siginContainer">
        {(status.error && (
          <div style={{ background: `tomato`, color: "#fff", padding: "1rem" }}>
            {status.error}
          </div>
        )) ||
          (status.success && (
            <div
              style={{ background: `#c6f0ce`, color: "#fff", padding: "1rem" }}
            >
              {status.success}
            </div>
          ))}
        <form onSubmit={handleSubmit(onSubmit)} id="signin">
          <div className="signin__title">
            <h2>Sign in</h2>
          </div>
          <div className="signin__socials">
            <div className="signin__facebook">Facebook</div>
            <div className="signin__google">Google</div>
          </div>
          
          <Input
            register={register}
            type="text"
            className=""
            name="email"
            placeholder="Email"
            errors={errors}
            required={true}
          />
          <Input
            register={register}
            type="password"
            className=""
            name="password"
            placeholder="Password"
            errors={errors}
            required={true}
          />
          <div className="signin__ctaBtn">
            <button type="submit" disabled={status.loading}>
             
              {(status.loading && "Loading") || "Signin"}
            </button>
          </div>
          <div className="signin__createAccount">
            Don't have an Account? <Link to="/signup" style={{color: "#2612b2", display: "inline"}}>Create account</Link>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Signin;
