import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Signin from "./components/signin";
import Signup from "./components/signup";
import GlobalFont from './fonts/font';
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const Home = React.lazy(() => import("./pages/Home"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_mHVOa9tPuAWLRI0PLqTdlP5m0014XO0Bee");

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <Router>
      <GlobalFont />
      <Switch>
        <Route path="/" exact>
          <React.Suspense fallback={<p>Loading...</p>}>
            <Home />
          </React.Suspense>
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/login">
          <Signin />
        </Route>
        <React.Suspense fallback={<p>Loading...</p>}>
          <ProtectedRoute path="/dashboard" component={Dashboard} />
        </React.Suspense>
      </Switch>
    </Router>
  </Elements>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
