import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./nav.scss";

interface INav {
  page: string;
}
const Nav: React.FC<INav> = ({ page }) => {
  const [hamburger, setHamburger] = useState(false);
  const screenWidth = window.innerWidth;
  return (
    <div
      className="navigation gradient-bg home"
      style={{
        paddingLeft: `${
          screenWidth > 768
            ? page !== "home"
              ? "10em"
              : ""
            : page !== "home"
            ? "1.5em"
            : ""
        }`,
        paddingRight: `${
          screenWidth > 768
            ? page !== "home"
              ? "10em"
              : ""
            : page !== "home"
            ? "1.5em"
            : ""
        }`,
      }}
    >
      <div className="navigation__company">
        <p>BT Kiosk</p>
        <div
          className="navigation__hamburger"
          onClick={() => setHamburger(!hamburger)}
        >
          <div className="navigation__hamburgerItem"></div>
          <div className="navigation__hamburgerItem"></div>
          <div className="navigation__hamburgerItem"></div>
        </div>
      </div>
      {screenWidth < 1200 ? (
        hamburger && (
          <div className="navigation__mobileNav">
            <div className="navigation__navItems">
              <nav>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">About Us</Link>
                  </li>
                  <li>
                    <Link to="#">Solution</Link>
                  </li>
                  <li>
                    <Link to="#">Contacts</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="navigation__ctaBtns">
              <Link to="/signup">Sign up</Link>
              <Link to="/login">Log in</Link>
            </div>
          </div>
        )
      ) : (
        <>
          <div className="navigation__navItems">
            <nav>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="#">About Us</Link>
                </li>
                <li>
                  <Link to="#">Solution</Link>
                </li>
                <li>
                  <Link to="#">Contacts</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="navigation__ctaBtns">
            <Link to="/signup">Sign up</Link>
            <Link to="/login">Log in</Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Nav;
