import React from "react";
import { Redirect, Route } from "react-router-dom";

//@ts-ignore
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  return (
    <Route
      {...rest}
      render={(props) => (token ? <Component /> : <Redirect to="/login" />)}
    />
  );
};

export default ProtectedRoute;
