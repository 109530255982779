import { createGlobalStyle } from 'styled-components';
import HelveticaNeue from './HelveticaNeue.ttf';
import HelveticaNeueBold from './HelveticaNeue-Bold.ttf';
import HelveticaNeueCondensedBlack from './HelveticaNeue-CondensedBlack.ttf';
import GilroyExtraBold from './Gilroy-ExtraBold.otf';

export default createGlobalStyle`

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-weight: normal;
    src: local('Helvetica Neue'),
      url(${HelveticaNeue}) format('truetype');
  }
  
  @font-face {
    font-family: 'Helvetica Bold';
    font-style: normal;
    font-weight: 400;
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Bold'),
      url(${HelveticaNeueBold}) format('truetype');
  }
  
  @font-face {
    font-family: 'Helvetica Condensed';
    font-style: normal;
    font-weight: 400;
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Condensed'),
      url(${HelveticaNeueCondensedBlack}) format('truetype');
  }
  
  @font-face {
    font-family: 'Gilroy Bold';
    font-style: normal;
    font-weight: 900;
    font-style: normal;
    src: local('Gilroy Bold'),
      url(${GilroyExtraBold}) format('truetype');
  }

`;
