import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className=" footer gradient-bg">
      <div className="footer__left">
        <h1>BT Kiosk</h1>
        <p>
          Companies in other industries have take the same approach achieved
          amazing in other industries
        </p>
      </div>
      <div className="footer__right ">
        <h4 className="footer__h4">Quick Link</h4>
        <ul className="">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="#">About Us</Link>
          </li>
          <li>
            <Link to="#">Solution</Link>
          </li>
          <li>
            <Link to="#">Contacts</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
