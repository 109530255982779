import axios from "axios";
import baseUrl from "./baseUrl";
const api: string = "auth/signup";


const post = (user: any) => {
  return axios.post(`${baseUrl}${api}`, user);
};

export default post;
