import React, { InputHTMLAttributes } from "react";

interface ISelect {
  label?: string;
  errors?: any;
  type: string;
  name: string;
  placeholder: string;
  register: string;
  list: { value: string; name: string }[];
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errors?: any;
  type: string;
  name: string;
  required: any;
  placeholder?: string;
  register: any; // declare register props
}

const Input: React.FC<InputProps> = ({
  name,
  errors,
  type,
  label,
  register,
  placeholder,
  required

}) => {
  return (
    <div className="">
      {label && (
        <div>
          <label>{label}</label>
        </div>
      )}

      <div className="input" style={{margin: `${!label ? '1em 0': '0.2em 0'}`}}>
        <input
          type={type}
          name={name}
          ref={register({required: required})}
          placeholder={placeholder}
          style={{ borderBottom: `${errors[name] && "1px solid tomato"}` }}
        />
      {errors[name] && <span style={{color: "tomato", fontSize: "0.8rem"}}>This field is required</span>}
      </div>
    </div>
  );
};

// export const InputSelect: React.FC<ISelect> = (props: ISelect) => {
//   return (
//     <div>
//       <div>
//         <label>{props.label}</label>
//       </div>
//       <div>
//         <select name={props.name}>
//           <option>Select...</option>
//           {props.list && props.list.length > 0
//             ? props.list.map((item, i: Number) => (
//                 <option key={`${i}`} value={item.value}>
//                   {item.name}
//                 </option>
//               ))
//             : ""}
//         </select>
//       </div>
//       {props.errors && <span>`${props.errors[name]}`</span>}
//     </div>
//   );
// };

export default Input;
